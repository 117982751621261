'use client'
import { useMemo, useState } from 'react'
import { RaButton, RaIcon } from '@components/core/index.core'
import { RaNavbarDropdown } from '@components/core/RaNavbarDropdown/RaNavbarDropdown'
import { ICONS } from '@constants/icons.constants'
import clsx from 'clsx'

import type { ThemeSettings } from '@typings'

import styles from './ra-topbar-destinations.module.scss'

interface RaTopbarDestinations {
  themeSettings: ThemeSettings
}

export const RaTopbarDestinations = ({
  themeSettings,
}: RaTopbarDestinations) => {
  const [open, setOpen] = useState(false)
  const { hide_destinations, all_destinations } = themeSettings

  const menuStyles = useMemo(() => {
    return clsx(
      styles['ra-topbar-destinations'],
      open && styles['ra-topbar-destinations__open']
    )
  }, [open])

  const handleOpenState = (newVal: boolean) => {
    if (hide_destinations) return null
    setOpen(newVal)
  }

  if (hide_destinations || !all_destinations?.title || !all_destinations?.url) {
    // return empty div to ensure other topbar items are at the correct position
    return <div className={menuStyles}></div>
  }

  return (
    <div
      onMouseEnter={() => handleOpenState(true)}
      onMouseLeave={() => handleOpenState(false)}
      onFocus={() => handleOpenState(true)}
      onBlur={() => handleOpenState(false)}
      className={menuStyles}>
      <RaIcon icon={ICONS.GLOBE_ICON} />
      <RaButton
        small
        link={all_destinations}
        label={all_destinations.title}
        variant="text-light"
        endIcon={
          <RaIcon
            icon={ICONS.CHEVRON_DOWN}
            color="light"
          />
        }
      />
      {themeSettings.menus.destinations && (
        <RaNavbarDropdown
          key="destinations"
          menu={themeSettings.menus.destinations}
          disablePrefetch
        />
      )}
    </div>
  )
}
