'use client'
import { useState } from 'react'

import type { ThemeSettings } from '@typings'
import {
  RaButton,
  RaIcon,
  RaPaper,
  RaRichText,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { SETTINGS } from '@constants/settings.constants'
import { useOutsideClick } from '@utilities/hooks/useOutsideClick.hooks'

import styles from './ra-topbar-contact.module.scss'

interface RaTopbarContact {
  themeSettings: ThemeSettings
  destination?: string
}

export const RaTopbarContact = ({
  themeSettings,
  destination,
}: RaTopbarContact) => {
  const [open, setOpen] = useState(false)
  const { hide_telephone_icon_on_mobile, hours, text_phone, phone } =
    themeSettings
  const isRoot =
    !destination || SETTINGS.DISABLE_CART_DOMAINS.includes(destination)

  const handleOpen = () => {
    setOpen(true)
  }

  const clickListener = useOutsideClick(() => {
    setOpen(false)
  })

  if (!isRoot && hide_telephone_icon_on_mobile) {
    return <div></div>
  }

  return (
    <div className={styles['ra-topbar-contact']}>
      <div className={styles['ra-topbar-contact--desktop']}>
        <RaButton
          variant="text-light"
          small
          label={text_phone}
          onClick={handleOpen}
          endIcon={
            <RaIcon
              icon={ICONS.CHEVRON_DOWN}
              color="light"
            />
          }
        />
      </div>
      <div className={styles['ra-topbar-contact--mobile']}>
        <RaButton
          variant="icon"
          icon={ICONS.MOBILE}
          ariaLabel={text_phone}
          onClick={isRoot && !phone ? handleOpen : null}
          link={
            !isRoot &&
            phone && {
              title: text_phone,
              url: `tel:${phone}`,
            }
          }
          textColor="light"
        />
      </div>

      <dialog
        open={open}
        className={styles['ra-topbar-contact__dialog']}>
        <div ref={clickListener}>
          <RaPaper
            padding={4}
            elevation="heavy">
            <RaRichText content={hours} />
          </RaPaper>
        </div>
      </dialog>
    </div>
  )
}
