'use client'
import {
  RaContainer,
  RaHeading,
  RaIllustration,
} from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'

import { ThemeFooterContact } from '@typings'

import { RaFooterContactPortal } from './RaFooterContactPortal'

import styles from './ra-footer-contact.module.scss'

export const RaFooterContact = (block: ThemeFooterContact) => {
  return (
    <RaContainer>
      <div className={styles['ra-footer-contact']}>
        <div>
          <RaIllustration index={1} />
          <RaHeading component="h2">{block.title}</RaHeading>
        </div>
        {block.icons.length > 0 && (
          <div className={styles['ra-footer-contact__portals']}>
            {Loop(block.icons, (icon, i) => (
              <RaFooterContactPortal
                key={i}
                icon={icon}
              />
            ))}
          </div>
        )}
      </div>
    </RaContainer>
  )
}
