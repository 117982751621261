'use client'
import { createContext, useContext, useEffect, useState } from 'react'

import { LOCAL_STORAGE } from '@constants/local-storage.constants'

import { GA4Events } from '@utilities/ga4-helpers/GA4Events.helpers'
import { getUserId } from '@utilities/helpers/get-user-id.helpers'
import { setLocalStorage } from '@utilities/helpers/local-storage.helpers'
interface UserDataProps {
  ip?: {
    raw: string
    hashed: string
  }
  userAgent: string
  userId: string
  documentCookies: string[]
  initUser: ({ ua, ip, id }) => void
  updateUser: (key: string, value: any) => void
  clearUser: () => void
  getCookie: (cookieID: string) => string | null
  setCookie: (cookieID: string, value: string) => void
}

const _default = {
  ip: {
    raw: null,
    hashed: null,
  },
  userAgent: null,
  userId: null,
}

const expiryInDays = 186

export const UserContext = createContext<UserDataProps>(null)
UserContext.displayName = 'UserContext'

export const UserContextProvider = ({ children, userData, GTM_ID }) => {
  const analytics = new GA4Events({ mode: 'client', gtmId: GTM_ID })
  const [user, setUser] = useState(_default)
  const [documentCookies, setDocumentCookies] = useState(null)

  // Methods
  const initUser = ({ ua, ip, id }) => {
    setUser(prev => ({ ...prev, userAgent: ua, ip, userId: id }))
  }

  const updateUser = (key: string, value: any) => {
    setUser(prev => ({ ...prev, [key]: value }))
  }

  const clearUser = () => {
    setUser(_default)
  }

  const getCookie = (cookieID: string) => {
    if (!documentCookies) return null
    const cookies = documentCookies?.map((cookie: string) => ({
      key: cookie.split('=')[0],
      value: cookie.split('=')[1],
    }))
    const match = cookies.find(({ key }) => key === cookieID)
    if (match) return match.value
  }

  const setCookie = (cookieID: string, value: string) => {
    const d = new Date()
    d.setTime(d.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
    const expires = `expires=${d.toUTCString()}`

    if (cookieID === 'cookiepermission') {
      document.cookie = `${cookieID}=${value};path=/;${expires}`
    } else {
      document.cookie = `${cookieID}=${value};${expires}`
    }

    analytics.setCookieConsent(cookieID, value)
  }

  useEffect(() => {
    setDocumentCookies(document.cookie ? document.cookie.split('; ') : [])
    if (!userData) return
    const { ua, ip, hashedIp } = userData
    if (ua && ip)
      initUser({
        ua,
        ip: {
          raw: ip,
          hashed: hashedIp,
        },
        id: getUserId(),
      })
    if (hashedIp) setLocalStorage(LOCAL_STORAGE.USER_IP_HASH, hashedIp)
  }, [userData])

  const context = {
    ...user,
    initUser,
    updateUser,
    clearUser,
    documentCookies,
    getCookie,
    setCookie,
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export const useUser = () => {
  return useContext(UserContext)
}
