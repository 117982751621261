'use client'
import { createContext, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/navigation'

import type {
  BuildingBlockPostType,
  SearchFilterOptionProps,
  SearchFilterProps,
} from '@typings'
import { essentialProductFields } from '@constants/wp-query-fields.constants'
import { buildingBlocks, productFilters } from '@services/index.services'
import { Loop } from '@utilities/helpers/core.helpers'

interface SearchPostsProps {
  query: object
  formInput: object | null
  availableFilters?: SearchFilterProps[]
  loadingFilters: boolean
  loadingFiltersError?: string
  selectedWithLabels?: SearchFilterOptionProps[]
  showLazyLoadButton: boolean
  setQuery: (value: object) => void
  handleFormInputChange: (key: string | number, value: string | number) => void
  getSearchFilters: (postTypes: string[], filters: string[]) => void
  updateSearchParams: (e: React.FormEvent) => void
  searchTripsByQueryParams: (query: object) => void
  lazyLoadMoreResults: () => void
  results: BuildingBlockPostType[]
  loadingResults: boolean
}

interface PromiseResult {
  status: 'fulfilled' | 'rejected'
  value?: object
}

const PER_PAGE = 7

export const SearchPosts = createContext<SearchPostsProps>(null)
SearchPosts.displayName = 'SearchPosts'

interface SearchPostsProviderProps {
  site?: string
  children: React.ReactNode
}

export const SearchPostsProvider = ({
  children,
  site,
}: SearchPostsProviderProps) => {
  const router = useRouter()
  const [query, setQuery] = useState({})
  const [availableFilters, setAvailableFilters] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [loadingFiltersError, setLoadingFiltersError] = useState(null)
  const [formInput, setFormInput] = useState<null | object>(null)
  const [results, setResults] = useState(null)
  const [loadingResults, setLoadingResults] = useState(true)
  const [currentPage, setCurrentPage] = useState(2)
  const [showLazyLoadButton, setShowLazyLoadButton] = useState(true)

  const getSearchFilters = async (postTypes: string[], filters: string[]) => {
    const promises: Promise<object>[] = []
    postTypes.forEach((postType: string) => {
      promises.push(
        productFilters({
          postType: postType, // Can be multiple posttypes
        })
      )
    })

    const res = await Promise.allSettled(promises)
      .then(res => {
        return res
      })
      .catch(err => {
        setLoadingFiltersError(true)
        return err
      })
    let results = res
      .filter((p: PromiseResult) => p.status === 'fulfilled')
      .map((p: PromiseResult) => p.value)

    if (!results || results.length === 0) return null
    if (results.length > 1) {
      results = results.flat()
    } else {
      results = results[0]
    }

    const filterOptions = Loop(filters, filter => {
      const lowercase = filter.toLowerCase()
      return {
        key: lowercase,
        label: lowercase.replace('_', ''),
        options: results[lowercase],
      }
    })

    setAvailableFilters(filterOptions)
    setLoadingFilters(false)
    setLoadingResults(false)
  }

  const updateSearchParams = (e: React.FormEvent) => {
    e.preventDefault()
    const query = {}
    const params = []
    for (const [key, value] of Object.entries(formInput)) {
      query[key] = value
      params.push(`${key}=${value}`)
    }
    const url = window.location.pathname + '?' + params.join('&')
    router.replace(url, { scroll: false })
    searchTripsByQueryParams(query)
  }
  const handleFormInputChange = (
    key: string | number,
    value: string | number
  ) => {
    setFormInput(prev => ({ ...prev, [key]: value }))
  }

  const searchTripsByQueryParams = async (query?: object) => {
    setResults([])
    setLoadingResults(true)

    const res = await buildingBlocks(
      site,
      {
        ...query,
        _fields: essentialProductFields('buildingblock'),
      },
      false
    )

    setLoadingResults(false)

    if (res && Array.isArray(res)) {
      setResults(res)
      if (res.length < PER_PAGE) {
        setShowLazyLoadButton(false)
      } else {
        setShowLazyLoadButton(true)
      }
    } else {
      setShowLazyLoadButton(false)
    }
  }

  const lazyLoadMoreResults = async () => {
    setLoadingResults(true)

    const query = {}
    if (formInput) {
      for (const [key, value] of Object.entries(formInput)) {
        query[key] = value
      }
    }

    const searchParams = {
      ...query,
      page: currentPage,
      per_page: PER_PAGE,
    }

    const res = await buildingBlocks(
      site,
      {
        ...searchParams,
        _fields: essentialProductFields('buildingblock'),
      },
      false
    )

    setLoadingResults(false)
    setCurrentPage(currentPage + 1)

    if (res && Array.isArray(res)) {
      const previousResults = results || []
      setResults([...previousResults, ...res])
      if (res.length < PER_PAGE) setShowLazyLoadButton(false)
    } else {
      setShowLazyLoadButton(false)
    }
  }

  const selectedWithLabels = useMemo(() => {
    if (!availableFilters) return null
    const selected = []
    availableFilters?.forEach((filter: SearchFilterProps) => {
      if (formInput?.[filter?.key]) {
        selected.push(
          filter.options?.find(
            option =>
              option?.term_id?.toString() === formInput[filter?.key]?.toString()
          )
        )
      }
    })

    return selected
  }, [results])

  return (
    <SearchPosts.Provider
      value={{
        query,
        formInput,
        availableFilters,
        results,
        loadingFilters,
        loadingResults,
        loadingFiltersError,
        selectedWithLabels,
        showLazyLoadButton,
        setQuery,
        getSearchFilters,
        handleFormInputChange,
        updateSearchParams,
        searchTripsByQueryParams,
        lazyLoadMoreResults,
      }}>
      {children}
    </SearchPosts.Provider>
  )
}

export const useSearchPosts = () => {
  return useContext(SearchPosts)
}
