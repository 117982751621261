'use client'
import { useRef } from 'react'

import { RaHeading, RaPortal, RaRichText } from '@components/core/index.core'

import styles from './ra-footer-contact.module.scss'

export const RaFooterContactPortal = ({ icon }) => {
  const ref = useRef(null)
  return (
    <div
      ref={ref}
      className={styles['ra-footer-contact__portal']}>
      <RaPortal
        ref={ref}
        color="dark">
        <div>
          <RaHeading
            alignment="center"
            component="h3">
            {icon.title}
          </RaHeading>
          <RaRichText
            className={styles['ra-footer-contact__portal__richtext']}
            content={icon.text}
          />
        </div>
      </RaPortal>
    </div>
  )
}
