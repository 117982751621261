import { IsSet } from '@utilities/helpers/core.helpers'

import { API } from '@api'

interface DotmailerPayload {
  name: string
  email: string
  optin: boolean
  optinType: string
}

export const dotmailer = async (payload: DotmailerPayload) => {
  if (!IsSet(payload)) return null

  const data = await API.Dotmailer.submit({ payload })

  return data
}
