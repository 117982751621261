// Please note - do not load Truspilot script in this component.
// Trustpilot topbar is above-the-fold and we need to minimise 3rd party scripts on initial page load
'use client'

import type { ThemeSettings } from '@typings'

import styles from './ra-trustpilot-topbar.module.scss'

interface RaTrustpilotProps {
  tpSettings: ThemeSettings['trustpilot_toolbar']
  loadExternal?: boolean
}

export const RaTrustpilotTopbar = ({
  tpSettings,
  loadExternal = true,
}: RaTrustpilotProps) => {
  if (loadExternal) {
    return (
      <div
        className={styles['ra-trustpilot-topbar']}
        dangerouslySetInnerHTML={{ __html: tpSettings }}></div>
    )
  }
}
