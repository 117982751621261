'use client'
import { RaIcon } from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'

import styles from './ra-footer-general.module.scss'

import { useSettings } from '@contexts/settings.context'

export const RaFooterGeneralSocials = ({ socials }) => {
  const { themeSettings } = useSettings()

  if (!themeSettings || !socials?.length) return null

  return (
    <div className={styles['ra-footer-general__column__socials']}>
      {Loop(socials, (social, i) => (
        <a
          key={i}
          href={themeSettings?.[`${social}_url`]}
          title={social}
          target="_blank">
          <RaIcon icon={ICONS[social?.toUpperCase()]} />
        </a>
      ))}
    </div>
  )
}
