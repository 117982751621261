'use client'
import { useRef, useState } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

import {
  RaButton,
  RaContainer,
  RaHeading,
  RaTypography,
  RaVisual,
} from '@components/core/index.core'
import { useUi } from '@contexts/ui.context'
import { dotmailer } from '@services/index.services'
import { ThemeFooterNewsletter } from '@typings'
import { translate } from '@utilities/helpers/core.helpers'
import { useIntersectionObserver } from '@utilities/hooks/useIntersectionObserver.hooks'

import styles from './ra-footer-newsletter.module.scss'

const RaFormStyles = dynamic(() =>
  import('@components/form-elements/RaFormStyles/RaFormStyles').then(
    ({ RaFormStyles }) => RaFormStyles
  )
)

const RaFormTextInput = dynamic(() =>
  import('@components/form-elements/RaFormTextInput/RaFormTextInput').then(
    ({ RaFormTextInput }) => RaFormTextInput
  )
)

const RaFormCheckbox = dynamic(() =>
  import('@components/form-elements/RaFormCheckbox/RaFormCheckbox').then(
    ({ RaFormCheckbox }) => RaFormCheckbox
  )
)

const RaFormEmailInput = dynamic(() =>
  import('@components/form-elements/RaFormEmailInput/RaFormEmailInput').then(
    ({ RaFormEmailInput }) => RaFormEmailInput
  )
)

export const RaFooterNewsletter = (block: ThemeFooterNewsletter) => {
  const elementRef = useRef()
  const ui = useUi()
  const { hasIntersected } = useIntersectionObserver(elementRef)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [optin, setOptin] = useState(false)
  const [checkboxError, setCheckboxError] = useState(null)
  const optinType = 'verifieddouble'

  const optinOptions = [
    {
      id: 1,
      text: translate('NEWSLETTER_OPTIN_LABEL'),
      value: 'true',
    },
  ]

  const handleOptInChange = (id, val) => {
    if (val === 'true') {
      setOptin(true)
      if (checkboxError) setCheckboxError(null)
    } else {
      setOptin(false)
    }
  }

  const submitForm = async e => {
    e.preventDefault()
    setLoading(true)
    if (!optin) {
      setCheckboxError([
        {
          key: 1,
          message: translate('FORM_FIELD_REQUIRED'),
        },
      ])
      return
    }

    const res = await dotmailer({ name, email, optin, optinType })
    if (!res?.error) {
      ui.showToaster({
        severity: 'success',
        title: `${email} ${translate('SUCCESFULLY_ADDED')}`,
      })
    } else {
      ui.showToaster({
        severity: 'error',
        title: res?.message || 'Something went wrong, please try again later',
      })
    }

    setLoading(false)
  }

  return (
    <div
      ref={elementRef}
      className={styles['ra-footer-newsletter']}>
      <ErrorBoundary>
        <RaContainer>
          <div className={styles['ra-footer-newsletter__wrapper']}>
            <RaHeading
              component="h2"
              color="light"
              alignment="center">
              {block.title}
            </RaHeading>
            {block.subtitle && (
              <RaTypography size="xl">{block.subtitle}</RaTypography>
            )}
            {hasIntersected && (
              <RaFormStyles theme="light">
                <form onSubmit={submitForm}>
                  <RaFormTextInput
                    id="name"
                    value={name}
                    onChange={setName}
                    label={translate('NEWSLETTER_NAME_PLACEHOLDER')}
                    disabled={loading}
                    required
                    color="light"
                  />
                  <RaFormEmailInput
                    id="email"
                    value={email}
                    onChange={setEmail}
                    label={translate('NEWSLETTER_EMAIL_PLACEHOLDER')}
                    disabled={loading}
                    required
                    color="light"
                  />
                  <RaFormCheckbox
                    label={translate('NEWSLETTER_OPTIN_ARIALABEL')}
                    id="optin"
                    options={optinOptions}
                    onChange={handleOptInChange}
                    disabled={loading}
                    hideFieldsetLegend
                    errors={checkboxError}
                  />
                  <RaButton
                    type="submit"
                    variant="secondary-footer"
                    fullWidth
                    disabled={loading}
                    label={translate('NEWSLETTER_SUBMIT_BUTTON')}
                  />
                </form>
              </RaFormStyles>
            )}
          </div>
        </RaContainer>
      </ErrorBoundary>
      <RaVisual color="light" />
    </div>
  )
}
