import Image from 'next/image'

import styles from './ra-visual.module.scss'
import { VISUALS } from '@constants/visuals.constants'
interface RaVisualProps {
  color?: 'dark' | 'light'
}

export const RaVisual = ({ color = 'dark' }: RaVisualProps) => {
  return (
    <div className={styles['wrapper']}>
      <Image
        className={styles['visual']}
        src={VISUALS.WORLD}
        alt="world"
        width={1500}
        height={600}
        loading="lazy"
        quality={20}
        style={{
          objectFit: 'cover',
          filter: color === 'dark' ? 'initial' : 'brightness(2)',
        }}
      />
    </div>
  )
}
