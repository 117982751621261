'use client'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

const RaModal = dynamic(() =>
  import('@components/features/RaModal/RaModal').then(({ RaModal }) => RaModal)
)
const RaToaster = dynamic(() =>
  import('@components/features/RaToaster/RaToaster').then(
    ({ RaToaster }) => RaToaster
  )
)
const RaLightbox = dynamic(() =>
  import('@components/features/RaLightbox/RaLightbox').then(
    ({ RaLightbox }) => RaLightbox
  )
)

export const RaNotificationWrapper = () => {
  return (
    <ErrorBoundary>
      <RaModal />
      <RaToaster />
      <RaLightbox />
    </ErrorBoundary>
  )
}
